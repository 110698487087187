import React from "react";
import {
  Grid,
  Grow,
  Slide,
  Hidden,
  AppBar,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NavMenuButton from "../components/navMenuButton";
import TextLoop from "react-text-loop";
import AnimatedLogo from "../components/biclastAnimatedLogo";
import LogoSVG from "../components/styles/biclast-logo.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  navBarSM: {
    flexGrow: 1,
    padding: "0px 0px",
    margin: "0px 0px 50px 0px",
    color: "#212121",
  },
  navBarLG: {
    flexGrow: 1,
    margin: "10px 0px 30px 0px",
    paddingLeft: "15%",
    paddingRight: "15%",
  },
  navBarLGMenuItem: {
    paddingRight: "4%",
  },
  titleLgContainer: {
    flexGrow: 1,
    paddingTop: 40,
    paddingLeft: "15%",
    paddingRight: "15%",
  },
  titleSmContainer: {
    flexGrow: 1,
    paddingTop: 30,
    paddingLeft: 10,
  },
  title: {
    flexGrow: 1,
    fontWeight: "bold",
  },
  servicesContainer: {
    marginTop: "10%",
  },
  overlineTitle: {
    fontSize: 20,
    color: "grey",
  },
  logoSmall: {
    width: 120,
  },
}));

const HomeV2 = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="column"
      justify="center"
      className={classes.root}
    >
      {/* SMALL NAV BAR FOR MOBILE SCREEN */}
      <Hidden mdUp>
        <Grid item xs={12} className={classes.navBarSM}>
          <AppBar position="static">
            <Toolbar style={{ justifyContent: "space-between" }}>
              <img
                className={classes.logoSmall}
                src={LogoSVG}
                alt="Company Logo"
              />
              <NavMenuButton />
            </Toolbar>
          </AppBar>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            direction="column"
            className={classes.titleSmContainer}
          >
            <Slide direction="left" in={true} mountOnEnter timeout={700}>
              <Grid item xs={12}>
                <Grid container direction="column" alignItems="flex-start">
                  <Typography variant="h3" className={classes.title}>
                    We are{" "}
                  </Typography>
                  <Typography variant="h3" className={classes.title}>
                    <TextLoop
                      children={[
                        "Biclast",
                        "Premium",
                        "Friendly",
                        "Flexible",
                        "Innovative",
                        "Focused",
                        "Family",
                        "Challenging",
                        "Reliable",
                        "Smart",
                        "Transparent",
                      ]}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Slide>
            <Slide direction="left" in={true} mountOnEnter timeout={1000}>
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.title}>
                  One idea can change the world.
                </Typography>
                <Typography variant="h6" className={classes.title}>
                  New ideas will discover new worlds.
                </Typography>
              </Grid>
            </Slide>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                spacing={1}
                className={classes.servicesContainer}
              >
                <Typography
                  variant="overline"
                  className={classes.overlineTitle}
                >
                  services
                </Typography>
                <Grow in={true} timeout={800}>
                  <Grid item xs={12} lg={6}>
                    <Grid container direction="column">
                      <Typography variant="h5" className={classes.title}>
                        BIG DATA DEVELOPMENT AND CONSULTING
                      </Typography>
                      <Typography variant="h5" className={classes.title}>
                        DATA STRATEGY
                      </Typography>
                      <Typography variant="h5" className={classes.title}>
                        DATA MINING & ANALYTICS
                      </Typography>
                      <Typography variant="h5" className={classes.title}>
                        BIG DATA ARCHITECTURE
                      </Typography>
                      <Typography variant="h5" className={classes.title}>
                        DATA VISUALIZATION
                      </Typography>
                    </Grid>
                  </Grid>
                </Grow>
                <Grow in={true} timeout={1000}>
                  <Grid item xs={12} lg={6}>
                    <Grid container direction="column">
                      <Typography variant="h5" className={classes.title}>
                        BUSINESS INTELLIGENCE
                      </Typography>
                      <Typography variant="h5" className={classes.title}>
                        PILOT PROJECT FOR INSIGHT
                      </Typography>
                      <Typography variant="h5" className={classes.title}>
                        RENT A SPECIALIST
                      </Typography>
                      <Typography variant="h5" className={classes.title}>
                        SUPPORT SERVICES
                      </Typography>
                    </Grid>
                  </Grid>
                </Grow>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      {/* LARGE NAV BAR FOR TABLET AND DESKTOP SCREENS */}
      <Hidden smDown>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center"
            className={classes.navBarLG}
          >
            <Grid item xs={3}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <AnimatedLogo />
              </Grid>
            </Grid>
            <Slide direction="down" mountOnEnter in={true}>
              <Grid item xs={9}>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                >
                  <Typography variant="h6" className={classes.navBarLGMenuItem}>
                    Projects
                  </Typography>
                  <Typography variant="h6" className={classes.navBarLGMenuItem}>
                    Services
                  </Typography>
                  <Typography variant="h6" className={classes.navBarLGMenuItem}>
                    Blog
                  </Typography>
                  <Typography variant="h6" className={classes.navBarLGMenuItem}>
                    Contact us
                  </Typography>
                </Grid>
              </Grid>
            </Slide>
          </Grid>
        </Grid>

        {/* PAGE TITLE INTRO H1*/}
        <Grid item xs={12}>
          <Grid
            container
            direction="column"
            className={classes.titleLgContainer}
          >
            <Slide direction="down" in={true} mountOnEnter timeout={1000}>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Typography variant="h2" className={classes.title}>
                    We are{" "}
                    <TextLoop
                      children={[
                        "Biclast",
                        "Premium",
                        "Friendly",
                        "Flexible",
                        "Innovative",
                        "Focused",
                        "Family",
                        "Challenging",
                        "Reliable",
                        "Smart",
                        "Transparent",
                      ]}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Slide>
            <Slide direction="down" in={true} mountOnEnter timeout={500}>
              <Grid item xs={12}>
                <Typography variant="h5" className={classes.title}>
                  One idea can change the world.
                </Typography>
                <Typography variant="h5" className={classes.title}>
                  New ideas will discover new worlds.
                </Typography>
              </Grid>
            </Slide>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                className={classes.servicesContainer}
              >
                <Grid item xs={12}>
                  <Typography
                    variant="overline"
                    className={classes.overlineTitle}
                  >
                    services
                  </Typography>
                </Grid>
                <Grow in={true} timeout={800}>
                  <Grid item xs={12} lg={6}>
                    <Grid container direction="column">
                      <Typography variant="h5" className={classes.title}>
                        BIG DATA DEVELOPMENT AND CONSULTING
                      </Typography>
                      <Typography variant="h5" className={classes.title}>
                        DATA STRATEGY
                      </Typography>
                      <Typography variant="h5" className={classes.title}>
                        DATA MINING & ANALYTICS
                      </Typography>
                      <Typography variant="h5" className={classes.title}>
                        BIG DATA ARCHITECTURE
                      </Typography>
                      <Typography variant="h5" className={classes.title}>
                        DATA VISUALIZATION
                      </Typography>
                    </Grid>
                  </Grid>
                </Grow>

                <Grow in={true} timeout={1000}>
                  <Grid item xs={12} lg={6}>
                    <Grid
                      container
                      direction="column"
                      justify="flex-end"
                      alignItems="flex-start"
                    >
                      <Typography variant="h5" className={classes.title}>
                        BUSINESS INTELLIGENCE
                      </Typography>
                      <Typography variant="h5" className={classes.title}>
                        PILOT PROJECT FOR INSIGHT
                      </Typography>
                      <Typography variant="h5" className={classes.title}>
                        RENT A SPECIALIST
                      </Typography>
                      <Typography variant="h5" className={classes.title}>
                        SUPPORT SERVICES
                      </Typography>
                    </Grid>
                  </Grid>
                </Grow>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default HomeV2;
