import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles, Typography } from "@material-ui/core";

export default function NavMenuButton() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const useStyles = makeStyles((theme) => ({
    menuIcon: {
        color: theme.palette.common.white,},
  }));
  const classes = useStyles();
  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MenuIcon fontSize="large" className={classes.menuIcon}/>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}><Typography variant="h5">Projects</Typography></MenuItem>
        <MenuItem onClick={handleClose}><Typography variant="h5">Services</Typography></MenuItem>
        <MenuItem onClick={handleClose}><Typography variant="h5">Blog</Typography></MenuItem>
        <MenuItem onClick={handleClose}><Typography variant="h5">Contact us</Typography></MenuItem>
      </Menu>
    </div>
  );
}
