import React from "react";
import Switch from "@material-ui/core/Switch";
import { Grid, Hidden, Typography } from "@material-ui/core";
import DarkTheme from "../styles/darkTheme";

const ThemeSwitch = (props) => {
  return (
    <>
      <Hidden mdUp>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <Typography variant="subtitle2">Night mode</Typography>
          <Switch
            checked={props.appTheme === DarkTheme}
            onChange={() => props.setDarkMode()}
          />
        </Grid>
      </Hidden>
      <Hidden smDown>
      <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          style={{ paddingRight: "16%" }}
        >
          <Typography variant="subtitle2">Night mode</Typography>
          <Switch
            checked={props.appTheme === DarkTheme}
            onChange={() => props.setDarkMode()}
          />
        </Grid>
      </Hidden>
    </>
  );
};

export default ThemeSwitch;
