import React, { useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Grid, Hidden, Typography } from "@material-ui/core";

export default function CookiesAlert() {
  const [open, setOpen] = useState(
    !(localStorage.getItem("cookies_policy_accepted") !== null
      ? JSON.parse(localStorage.getItem("cookies_policy_accepted"))
      : false)
  );

  const handleClose = (event, reason) => {
    localStorage.setItem("cookies_policy_accepted", true);
    setOpen(false);
  };

  return (
    <Grid container>
      <Hidden mdUp>
        <Grid item xs={12}>
          <Snackbar
            style={{ paddingBottom: 10 }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={open}
            onClose={handleClose}
            message={
              <>
                <Typography>
                  We use cookies to improve your best experience with our
                  website. By continuing to use this website, you accept our use
                  of cookies.
                </Typography>
              </>
            }
            action={
              <>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleClose}
                >
                  <CloseIcon fontSize="large" />
                </IconButton>
              </>
            }
          />
        </Grid>
      </Hidden>
      <Hidden smDown>
        <Grid item>
          <Snackbar
            style={{ width: "100%" }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={open}
            onClose={handleClose}
            message="We use cookies to improve your best experience with our website. By continuing to use this website, you accept our use of cookies. "
            action={
              <>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleClose}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </>
            }
          />
        </Grid>
      </Hidden>
    </Grid>
  );
}
