import { createMuiTheme } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

const DarkTheme = createMuiTheme({
  typography: {
    fontFamily: "Catamaran, sans-serif",
  },
  palette: {
    type: "dark",
    background: {
      default: "#2c292d",
    },
    primary: {
      main: grey[900],
    },
  },
});

export default DarkTheme;
