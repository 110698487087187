import { createMuiTheme } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

const LightTheme = createMuiTheme({
  typography: {
    fontFamily: "Catamaran, sans-serif",
  },
  palette: {
    primary: {
      main: grey[900],
    },
    background: {
      default: "#fff",
    },
  },
});

export default LightTheme;
