import React, { useState } from "react";
import "./App.css";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
// import NavGrid from "./components/navGrid";
import HomeV2 from "./pages/homeV2";
import CookiesAlert from "./components/cookiesAlertBar";
import ThemeSwitch from "./components/themeSwitch";
import DarkTheme from "./styles/darkTheme";
import LightTheme from "./styles/lightTheme";

function App() {

  const [appTheme, setAppTheme] = useState(DarkTheme);
  
  return (
    <ThemeProvider theme={{...appTheme}}>
      <CssBaseline />
      <ThemeSwitch
        appTheme={appTheme}
        setDarkMode={() => setAppTheme(appTheme === DarkTheme ? LightTheme : DarkTheme)}
      />
      {/* <NavGrid /> */}
      <HomeV2 />
      <CookiesAlert />
    </ThemeProvider>
  );
}

export default App;
